import Shutter from '@/mixins/Shutter.js'

export default {
    mixins: [Shutter],
    methods: {
        getCongelationShutters(props) {
            return {
                'prestockage': {
                    name: 'prestockage',
                    title: this.$t('menu.congelation.prestockage'),
                    cmpPath: 'components/Congelation/PrestockageListe',
                    props
                },
                'add-control-lot': {
                    name: 'add-control-lot',
                    title: this.$t('monte.congelation.add_controle_qualite'),
                    cmpPath: 'components/Congelation/AddControlLot',
                    props,
                    onOk: this.reload,
                },
                'controls-lot': {
                    name: 'controls-lot',
                    title: this.$t('monte.congelation.controle_qualite'),
                    cmpPath: 'components/Congelation/ControlsLot',
                    props,
                    onOk: this.reload,
                },
                'stallion-campagne-liste': {
                    name: 'stallion-campagne-liste',
                    title: this.$t('monte.congelation.liste_campagnes'),
                    cmpPath: 'components/Congelation/StallionCampagneListe',
                    props
                },
                'campagne-preview': {
                    name: 'campagne-preview',
                    title: this.$t('monte.congelation.campagne'),
                    cmpPath: 'components/Congelation/CampagnePreview',
                    props
                },
                'edit-lot': {
                    name: 'edit-lot',
                    title: this.$t('monte.congelation.edit_lot'),
                    cmpPath: 'components/Congelation/EditLot',
                    props,
                    onOk: this.reload
                },
                'delete-campagne': {
                    name: 'delete-campagne',
                    title: this.$t('monte.congelation.delete_campagne'),
                    cmpPath: 'components/Congelation/DeleteCampagne',
                    props,
                    onOk: this.load_campagnes
                },
                'stallion-stock-liste': {
                    name: 'stallion-stock-liste',
                    title: this.$t('monte.congelation.stocks'),
                    cmpPath: 'components/Congelation/StallionStocksListe',
                    props
                },
                'emplacement-lot': {
                    name: 'emplacement-lot',
                    title: this.$t('monte.congelation.emplacements'),
                    cmpPath: 'components/Congelation/EmplacementLot',
                    props,
                    onOk: this.reload
                },
                'emplacement-detail': {
                    name: 'emplacement-detail',
                    title: this.$t('monte.congelation.emplacement_detail'),
                    cmpPath: 'components/Congelation/EmplacementDetail',
                    props,
                    onOk: this.reload
                }
            }
        },

        setUpPrestockage() {
            const shutters = this.getCongelationShutters()
            this.shutterPanel().open(shutters['prestockage'])
        },

        setUpAddControlLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['add-control-lot'])
        },

        setUpControlsLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['controls-lot'])
        },

        setUpCampagneStallionListe(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['stallion-campagne-liste'])
        },

        setUpCampagneStallion(campagne) {
            const shutters = this.getCongelationShutters({campagne})
            this.shutterPanel().open(shutters['campagne-preview'])
        },

        setUpEditLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['edit-lot'])
        },

        setUpDeleteCapagne(campagne) {
            const shutters = this.getCongelationShutters({campagne})
            this.shutterPanel().open(shutters['delete-campagne'])
        },

        setUpStallionStocksListe(horse_id) {
            const shutters = this.getCongelationShutters({horse_id})
            this.shutterPanel().open(shutters['stallion-stock-liste'])
        },

        setUpEmplacementLot(lot) {
            const shutters = this.getCongelationShutters({lot})
            this.shutterPanel().open(shutters['emplacement-lot'])
        },

        setUpEmplacementDetail(emplacement) {
            const shutters = this.getCongelationShutters({emplacement})
            this.shutterPanel().open(shutters['emplacement-detail'])
        }
    }
}
